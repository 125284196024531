import { Edge } from '../edges';
import { NodeInterface, RelatedNodesInterface } from '../nodes';

export interface WebsiteInterface extends NodeInterface {
	// SAVED
	content?: string;
	htmlContent?: string;
	path: string;
	faviconPath?: string;
	isReadable?: boolean;
}

export interface WebsiteUpdateBody {
	name?: string;
}

export interface WebsiteCreateResponse extends WebsiteInterface {
	edges?: Edge[];
}

export interface WebsiteGetFromUrlResponse extends WebsiteInterface {
	relatedNodes: RelatedNodesInterface[];
}
