import { CustomPropertyKeys } from './id-keys';
import { ReachIntegration } from '../integrations';

// moving this to helper.function.ts will cause a circular dependency
export const integrationToAssociatedCustomProperties: {
	[key in ReachIntegration]: Array<CustomPropertyKeys>;
} = {
	[ReachIntegration.BROWSER_PLUGIN]: [],
	[ReachIntegration.CSV]: [],
	[ReachIntegration.DROPBOX_PAPER]: [],
	[ReachIntegration.EVERNOTE]: [CustomPropertyKeys.EVERNOTE_GUID],
	[ReachIntegration.GOOGLE_DOCS]: [],
	[ReachIntegration.GOOGLE_DRIVE]: [
		CustomPropertyKeys.GOOGLE_DRIVE_GUID,
		CustomPropertyKeys.GOOGLE_DRIVE_VERSION,
		CustomPropertyKeys.GOOGLE_DRIVE_URL,
		CustomPropertyKeys.GOOGLE_DRIVE_NAME,
	],
	[ReachIntegration.PHONE_CONTACTS]: [],
	[ReachIntegration.POCKET]: [
		CustomPropertyKeys.POCKET_RESOURCE_ID,
		CustomPropertyKeys.POCKET_ACCOUNT_USERNAME,
		CustomPropertyKeys.ARCHIVED,
		CustomPropertyKeys.WEBPAGE_TYPE,
		CustomPropertyKeys.LAST_INTEGRATION_SYNC,
		CustomPropertyKeys.STARRED,
	],
	[ReachIntegration.QUIP]: [],
	[ReachIntegration.SITEMAP]: [CustomPropertyKeys.LAST_INTEGRATION_SYNC],
	[ReachIntegration.ZAPIER]: [],
	// TODO(814-sync-workspaces-integration): check if we added new cps
	[ReachIntegration.SHARE_WORKSPACES]: [],
	[ReachIntegration.RECEIVE_WORKSPACES]: [CustomPropertyKeys.RECEIVE_WORKSPACES_SOURCE_NODE_ID],
};
