import { IntegrationBaseInterface } from './integration-base.interface';
import { ResourceListInterface } from '../utils';
import { ReachIntegration } from './integration-type.enum';
import { IntegrationSyncableInterface } from './integration-syncable.interface';

interface IntegrationListMetaInterface {
	cooldowns: Record<ReachIntegration, Date | null>;
}

export type IntegrationListInterface = ResourceListInterface<
	IntegrationBaseInterface,
	IntegrationListMetaInterface
>;

export type IntegrationSyncListInterface = ResourceListInterface<
    IntegrationSyncableInterface,
	IntegrationListMetaInterface
>;
