export enum UserFeatureFlags {
	IMPORT_EXPORT = 'IMPORT_EXPORT',
	EXTENDED_SEARCH = 'EXTENDED_SEARCH',
	CREATE_AND_DELETE_OWN_NOTE_TEMPLATES = 'CREATE_AND_DELETE_OWN_NOTE_TEMPLATES',
	GOOGLE_DRIVE_INTEGRATION = 'GOOGLE_DRIVE_INTEGRATION',
	CHAT_WIDGET = 'CHAT_WIDGET',
	CHAT_INTEGRATIONS = 'CHAT_INTEGRATIONS',
	GENERATE_DOCUMENT_IMAGE_CHUNKS = 'GENERATE_DOCUMENT_IMAGE_CHUNKS',
	SHARE_WORKSPACES_INTEGRATION = 'SHARE_WORKSPACES_INTEGRATION',
	RECEIVE_WORKSPACES_INTEGRATION = 'RECEIVE_WORKSPACES_INTEGRATION',
}
