import { Nullable } from '../../typescript';
import { RequestPaginationInterface } from '../../requests';
import { ShareWorkspacesJoinCodeInterface } from './share-workspaces-join-code.interface';
import { ShareWorkspacesIntegrationMemberInterface } from './share-workspaces-integration-responses.interface';

export interface RegisterShareWorkspacesIntegrationRequestsInterface {
	name: string;
	workspaceIds: string[];
	autoCreateNewWorkspaces: boolean;
}

export interface UpdateShareWorkspacesIntegrationRequestsInterface {
	integrationId: string;
	name?: string;
	workspaceIds?: string[];
	autoCreateNewWorkspaces?: boolean;
}

export interface CreateShareWorkspacesIntegrationJoinCodeInterface {
	integrationId: string;
	validFrom: Nullable<Date>;
	validUntil: Nullable<Date>;
}

export interface RevokeRegisterShareWorkspacesIntegrationJoinCodeInterface {
	integrationId: string;
	joinCode: string;
}

export interface KickMemberShareWorkspacesIntegrationInterface {
	integrationId: string;
	userId: string;
}

export interface ListMembersShareWorkspacesIntegrationInterface
	extends RequestPaginationInterface<ShareWorkspacesIntegrationMemberInterface> {
	integrationId: string;
}

export interface ListJoinCodesShareWorkspacesIntegrationInterface
	extends RequestPaginationInterface<ShareWorkspacesJoinCodeInterface> {
	integrationId: string;
}

export interface ShareWorkspacesIntegrationMembersInterface {
	count: number;
	members: ShareWorkspacesIntegrationMemberInterface[];
}
