import { Nullable } from '../../typescript';

type SharerWorkspaceId = string;
type ReceiverWorkspaceId = string;

export interface CheckJoinCodeReceiveWorkspacesIntegrationRequestsInterface {
	joinCode: string;
}

export interface RegisterReceiveWorkspacesIntegrationRequestsInterface {
	joinCode: string;
	workspacesMapping: Record<SharerWorkspaceId, Nullable<ReceiverWorkspaceId>>;
}

export interface UpdateReceiveWorkspacesIntegrationRequestsInterface {
	integrationId: string;
	workspacesMapping?: Record<SharerWorkspaceId, Nullable<ReceiverWorkspaceId>>;
}
