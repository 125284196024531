import { ChatMessageInteractableComponentEnum } from './chat-message-interactable-component.enum';
import { ChatWidgetEmailNotification } from '../chat-widget/chat-widget-email-notification-data';
import { ChatWidgetEmailNotificationModeEnum } from '../chat-widget';

export type ChatMessageInteractableComponentMap = {
	[ChatMessageInteractableComponentEnum.BUTTON]: {
		text: string;
		redirect: string;
		buttonClicked: boolean;
	};
	[ChatMessageInteractableComponentEnum.EMAIL]: {
		notification: ChatWidgetEmailNotification<ChatWidgetEmailNotificationModeEnum>;
		reactionEmail?: string;
	};
};
export type ChatMessageInteractableComponentToType<
	TYPE extends ChatMessageInteractableComponentEnum
> = TYPE extends keyof ChatMessageInteractableComponentMap
	? ChatMessageInteractableComponentMap[TYPE]
	: null;

export type ChatMessageInteractableComponent<
	TYPE extends ChatMessageInteractableComponentEnum = ChatMessageInteractableComponentEnum
> = {
	interactableComponentType: TYPE;
} & (null extends ChatMessageInteractableComponentToType<TYPE>
	? { interactableComponentType: TYPE }
	: ChatMessageInteractableComponentToType<TYPE>);
