import { ReceiveWorkspacesIntegrationInterface } from './receive-workspaces-integration.interface';

export interface ReceiveWorkspacesIntegrationSharedWorkspaceMetadataInterface {
	id: string;
	name: string;
	emoji?: string;
}

export type ReceiveWorkspacesIntegrationShareIntegrationMetadataInterface = {
	shareName: string;
	isAlreadyMember: boolean;
	sharedWorkspaces: Array<ReceiveWorkspacesIntegrationSharedWorkspaceMetadataInterface>;
};

export interface ReceiveWorkspacesRegisterResponseInterface {
	integration: ReceiveWorkspacesIntegrationInterface;
	execution: {
		execution: null;
	};
}

export interface ReceiveWorkspacesIntegrationGetSharedWorkspacesResponse {
	sharedWorkspaces: Array<ReceiveWorkspacesIntegrationSharedWorkspaceMetadataInterface>;
	workspacesMapping: ReceiveWorkspacesIntegrationInterface['workspacesMapping'];
}
