import { Nullable } from '../typescript';

export interface ChatWidgetEmailSubscriptionInterface {
	id: string;
	widgetId: string;
	sessionId: string;
	messageId: Nullable<string>;
	email: string;
	category: Nullable<string>;
	subscribedAt: Date;
	messageTimestamp: Nullable<Date>;
}
