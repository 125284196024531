import { SearchParams } from '../search';
import { Nullable } from '../typescript';
import { ChatWidgetIconSizeEnum } from './chat-widget-icon-size.enum';
import { ChatMessageInteractableComponent } from '../chat/chat-message-interactable-component.types';
import { ChatWidgetModeEnum } from './chat-widget-mode.enum';
import { ChatWidgetSystemFrequencyEnum } from './chat-widget-system-frequency.enum';
import { ChatWidgetSystemTriggerEnum } from './chat-widget-system-trigger.enum';
import { ChatWidgetThemeEnum } from './chat-widget-theme.enum';
import { ChatMessageInteractableComponentEnum } from '../chat/chat-message-interactable-component.enum';
export enum ChatWidgetPageLocationEnum {
	BOTTOM_RIGHT = 'BOTTOM_RIGHT',
	BOTTOM_LEFT = 'BOTTOM_LEFT',
}

export interface ChatWidgetFlagInterface {
	id: string;
	name: string;
	colour: number[];
}

export interface ChatWidgetCategoryInterface {
	name: string;
	description: string;
	colour: number[];
	action?: Nullable<ChatWidgetSystemActionInterface>;
}

export interface ChatWidgetInteractableComponentActionInterface {
	active: boolean;
	interactableComponent: ChatMessageInteractableComponent<ChatMessageInteractableComponentEnum>;
}

export interface ChatWidgetSystemActionInterface
	extends ChatWidgetInteractableComponentActionInterface {
	trigger: ChatWidgetSystemTriggerEnum;
	content: string;
	frequencyType: ChatWidgetSystemFrequencyEnum;
	frequency: number;
}

export interface ChatWidgetUtmInterface {
	utmSource?: Nullable<string>;
	utmMedium?: Nullable<string>;
	utmCampaign?: Nullable<string>;
	utmTerm?: Nullable<string>;
	utmContent: boolean;
}

export interface ChatWidgetCustomizedResponsesInterface {
	couldNotAnswer: Nullable<ChatWidgetCustomizableMessageInterface>;
	speakToAHuman: Nullable<ChatWidgetCustomizableMessageInterface>;
}

export interface ChatWidgetInterface {
	id: string;
	createdAt: number;
	updatedAt: number;
	lastUsedAt: Nullable<number>;
	settings: ChatWidgetSettingsInterface;
	customization: ChatWidgetCustomizationInterface;
	flags: ChatWidgetFlagInterface[];
	integrationIds: Array<string>;
	customizedResponses: ChatWidgetCustomizedResponsesInterface;
}

export interface ChatWidgetCustomizableMessageInterface {
	response: string;
	action?: Nullable<ChatWidgetInteractableComponentActionInterface>;
}

export interface ChatWidgetSettingsInterface {
	name: string;
	active: boolean;
	query: Required<SearchParams>;
	instructions: Nullable<string>;
	showWebsites: boolean;
	showFiles: boolean;
	contactUsRedirect: string;
	whitelist: string[];
	categories: ChatWidgetCategoryInterface[];
	utm: ChatWidgetUtmInterface;
	mode: ChatWidgetModeEnum;
}

export interface ChatWidgetCustomizationInterface {
	logoImageUrl: Nullable<string>;
	logoThumbnailUrl: Nullable<string>;
	iconImageUrl: Nullable<string>;
	iconThumbnailUrl: Nullable<string>;
	subtitle?: Nullable<string>;
	title?: Nullable<string>;
	welcomeMessage: string[];
	colours: ChatWidgetCustomizationColoursInterface[];
	defaultQuestions: string[];
	inputPlaceholder?: Nullable<string>;
	systemMessageInputPlaceholder?: Nullable<string>;
	popUpMessage?: Nullable<string>;
	errorMessage?: Nullable<string>;
	pageLocation?: ChatWidgetPageLocationEnum;
	clearText?: Nullable<string>;
	contactText?: Nullable<string>;
	sendText?: Nullable<string>;
	popupMessageDisabled: boolean;
	iconSize: ChatWidgetIconSizeEnum;
	initialSearchTitle?: Nullable<string>;
	initialSearchSubtitle?: Nullable<string>;
	oldMessages?: Nullable<string>;
	theme: ChatWidgetThemeEnum;
}

export interface ChatWidgetCustomizationColoursInterface {
	name: string;
	primary: Nullable<number[]>;
	primaryHover: Nullable<number[]>;
	textOverPrimary: Nullable<number[]>;
}
