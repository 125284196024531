import { ReachIntegration } from './integration-type.enum';
import { IntegrationUpdateTypeEnum } from './integration-update-type.enum';

export interface IntegrationBaseInterface {
	id: string;
	type: ReachIntegration;
	displayName: string;
	updateType: IntegrationUpdateTypeEnum;
	canBeRemoved: boolean;
	targetedWorkspaces: string[];
	createdAt: Date;
	//status: JobStatusEnum;
}
