import { ChatWidgetEmailNotificationModeEnum } from './chat-widget-email-notification-mode.enum';

export type ChatWidgetEmailNotificationMap = {
	[ChatWidgetEmailNotificationModeEnum.SEND_TO_NOTIFICATION_EMAIL]: {
		email: string;
		subject?: string;
		content?: string;
	};
	[ChatWidgetEmailNotificationModeEnum.SEND_TO_NOTIFICATION_EMAIL_AND_USER_EMAIL]: {
		email: string;
		subject?: string;
		content?: string;
	};
};

export type ChatWidgetEmailNotificationToType<TYPE extends ChatWidgetEmailNotificationModeEnum> =
	TYPE extends keyof ChatWidgetEmailNotificationMap ? ChatWidgetEmailNotificationMap[TYPE] : null;

export type ChatWidgetEmailNotification<
	TYPE extends ChatWidgetEmailNotificationModeEnum = ChatWidgetEmailNotificationModeEnum
> = {
	mode: TYPE;
} & (null extends ChatWidgetEmailNotificationToType<TYPE>
	? { mode: TYPE }
	: ChatWidgetEmailNotificationToType<TYPE>);
