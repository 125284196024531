export const parseStringifiedInteger = (value: unknown): number => {
	if (typeof value === 'number') {
		if (!Number.isInteger(value)) {
			return NaN;
		}
		return value;
	}
	if (typeof value !== 'string') {
		return NaN;
	}
	if (!/^(-?[1-9]\d*|0)$/.test(value)) {
		return NaN;
	}
	return Number(value);
};
