import { Nullable } from '../../typescript';
import { IntegrationExecutionInterface } from '../integration-execution.interface';
import { PipelineInterface, PipelinePayloadInterface } from '../../pipelines';
import { ShareWorkspacesIntegrationInterface } from './share-workspaces-integration.interface';
import { ShareWorkspacesJoinCodeInterface } from './share-workspaces-join-code.interface';

export interface ShareWorkspacesIntegrationMemberInterface {
	id: string;
	integrationId: string;
	workspacesMapping: Record<string, Nullable<string>>;
	fullName: Nullable<string>;
	imageUrl: Nullable<string>;
	thumbnailUrl: Nullable<string>;
	joinCodeUsed: string;
	joinedAt: Date;
}

export interface ShareWorkspacesRegisterResponseInterface {
	integration: ShareWorkspacesIntegrationInterface;
	execution: {
		execution?: IntegrationExecutionInterface | null;
		pipeline?: PipelineInterface;
		payload?: PipelinePayloadInterface;
	};
}

export type ShareWorkspacesListJoinCodesResponseInterface = {
	joinCodes: Array<ShareWorkspacesJoinCodeInterface>;
	count: number;
};

export type ShareWorkspacesCreateJoinCodeResponseInterface = ShareWorkspacesJoinCodeInterface;

export type ShareWorkspacesRevokeJoinCodeResponseInterface = Record<never, never>;

export type ShareWorkspacesKickMemberResponseInterface = Record<never, never>;

export type ShareWorkspacesExecuteIntegrationResponseInterface = IntegrationExecutionInterface;

export type ShareWorkspacesListMembersResponseInterface = {
	members: Array<ShareWorkspacesIntegrationMemberInterface>;
	count: number;
};
